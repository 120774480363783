.Settings-Switch-Label {
  position: absolute;
  bottom: 0;
  padding-bottom: 5px;
}

.Settings-Tab {
  overflow: hidden auto;
  max-height: 57vh;
}

.Hide-Tab {
  display: none;
}