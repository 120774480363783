.static-modal-body-height {
    max-height: calc(100vh - 230px);
    overflow-y: auto;
}

.bootbox {
    z-index: 1500;
    overflow-y: hidden;
}

.table-striped > tbody > tr.frozen > td {
    background-color: #f0f0f1;
    color: #98999a;
}

.table-striped > tbody > tr.accepted > td {
    background-color: #7fc1c2;
}

.table-striped > tbody > tr.veryold > td {
    background-color: rgba(249, 188, 143, 0.4);
}

.table-striped > tbody > tr.cancelled > td {
    background-color: rgba(255, 0, 20, 0.4);
}

.col-no-gutter {
    padding-right: 5px;
    padding-left: 5px;
}

.form-parent-group {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 15px;
}

.form-parent-group:before {
    display: table;
    content: " ";
}

.form-parent-group:after {
    clear: both;
    display: table;
    content: " ";
}

.thumb-wrapper {
    display: flex;
}

.position-relative {
    position: relative!important;
}

.thumb-img-wrapper {
    position: relative;
    width: 75px;
    height: 75px;
    min-width: 75px;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    display: flex;
    margin-bottom: 20px;
}

.thumb-visitor-img {
    position: absolute;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    object-fit: cover;
}

div.dataTables_wrapper div.dataTables_filter {
    margin-left: -30px;
}

.thumb-detail-wrapper {
    line-height: 17px;
    margin-top: 4%;
    margin-left: 80px;
}

.thumb-visitor-name {
    font-size: 17px;
    display: block;
    margin: 0px;
}
.thumb-detail-wrapper
.thumb-visitor-company {
    font-size: 13px;
    margin: 0px;
    color: rgba(122, 122, 122, .9) !important;
    display: block;
}

.thumb-visitor-visitee {
    font-size: 13px;
    margin: 0px;
    color: rgba(122, 122, 122, .9) !important;
    font-style: italic;
    display: block;
}

.recent-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 75px;
}

.ant-card-head {
    min-height: 64px !important;
}

#page-wrapper.sidebar-visible {
    .appointment-summary-card {
        .ant-card-head-wrapper {
            display: block;
        }
        .ant-card-extra {
            float: initial;
            padding: 0px 0px 20px 0px;
        }
    }
}

.message-content {
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
}

.Message-Template-List {
    margin: -24px!important;

    .ant-table-fixed {
        table-layout: fixed;
    }

    .ant-table-tbody > tr > td {
        word-wrap: break-word;
        word-break: break-all;
    }
}

.Location-Name-Column {
    width: 45%;
}
.Form-Name-Column,
.Form-External-Code-Column {
    word-break: break-all;
}

.Form-Name-Column {
    width: 60%;
}

.Form-External-Code-Column {
    width: 25%;
}

.Form-Name-Link {
    word-break: break-all;
}

.Form-Builder-Name {
    display: flex;
    flex-wrap: wrap;
    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 20%;
    }
}

@media only screen and (max-width: 992px) {
    .Form-Builder-Name {
        span {
            width: 15%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .Form-Builder-Name {
        span {
            width: 8%;
        }
    }
}

@media only screen and (max-width: 600px) {
    body {
        font-size: 14px!important;
    }
    label {
        margin-top: 10px;
    }

    .btn {
        font-size: 2rem;
    }

    .form-control {
        font-size: 1.5rem;
    }

    #print-label-btn {
        width: 100%;
        margin: 10px 0;
    }

    .btn-close-checkin,
    #checkin-visitor-btn,
    #checkout-visitor-btn {
        width: 100%;
    }

    .w-o-gutter .btn-success {
        margin-top: 20px;
    }

    .w-o-gutter.no-margin-top .btn-success,
    .no-margin-top .btn-success {
        margin-top: 0;
    }

    .panel-heading {
        padding-bottom: 20px;
    }

    #page-wrapper {
        margin-top: 15px;
    }

    div.dataTables_wrapper div.dataTables_filter label {
        width: 80%;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        font-size: 2rem;
        width: 90%;
    }

    .appointment-summary-card {
        .ant-card-head-wrapper {
            display: block;
        }
        .ant-card-extra {
            float: initial;
            padding: 0px 0px 20px 0px;
        }
    }

    .message-content {
        max-width: 100px;
    }
}

@media only screen and (max-width: 600px) and (orientation: landscape) {
    body {
        font-size: 16px!important;
    }
    .btn {
        font-size: 2rem;
    }

    .form-control {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 600px) {
    body {
        font-size: 16px!important;
    }
    div.dataTables_wrapper div.dataTables_filter label {
        width: 80%;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        width: 90%;
    }
}

@media only screen and (min-width: 768px) {
    div.dataTables_wrapper div.dataTables_filter label {
        width: unset;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        width: unset;
    }
}

@media screen and (max-width: 1198px) and (min-width: 768px) {
    .Location-Name-Column {
        width: 55%;
        padding: 16px 8px !important;
    }
}
